require("floatthead")

/**
 * Called if the document was loaded.
 * Appends listeners.
 */
const onDocumentReady = () => {

    /**
     * Called if the value of the user management search field changed.
     * Searches in the user management table for data-search-value occurences of the given value.
     * If any was found in one column, the row will be shown. All other rows will be hidden.
     * The search is a simple "contains", not "equals" for each search value.
     * If the value of the search field is empty, all rows will be shown.
     * The search is not case sensitive.
     *
     * @param el The search field element
     */
    const userManagementSearchChanged = (el) => {
        const searchValue = el.value.toLowerCase();

        $('#tableUserManagement > tbody > tr').each((i, row) => {
            // Show if the search value is empty
            if (!searchValue) {
                row.style.display = 'table-row';
                return;
            }

            // Get columns that has the data-search-value attribute
            const columns = $(row).children().filter((i, column) => {
                return $(column).data('search-value') !== undefined;
            });

            // Search for an occurence of the search value in the data-search-value attributes
            const matchedColumns = $(columns).filter((i, column) => {
                const dataSearchValue = $(column).data('search-value');
                if (!dataSearchValue) {
                    return false;
                }

                return dataSearchValue.toLowerCase().includes(searchValue);
            });

            if (matchedColumns.length === 0) {
                row.style.display = 'none';
            }
            else {
                row.style.display = 'table-row';
            }

        });
    }

    $('#userManagementSearch').keyup((e) => {
        userManagementSearchChanged(e.target);

        // triggers the repositioning of the fixed table header using theadflow
        const tableContainer = $('#userManagementSearch').closest('.table-responsive');
        if (tableContainer.scrollLeft() > 0) {
            $('#userManagementSearch').closest('.table-responsive').animate({ scrollLeft: 0 });
        }
    });

}

$(document).on('turbolinks:load', onDocumentReady);
